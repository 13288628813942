import { ISequence } from './sequence';

import { attr, fk } from 'redux-orm';
import { BaseModel } from './base';
import { ContactTagResponse } from '@copilot/data/responses/interface';

export interface IInboxMessage {
	id: string;
	campaignId: string;
	campaignName: string;
	campaignType: string;
	orgMemberId: string;
	orgMemberName: string;
	campaignMemberId: string;
	contactId: string;
	connectionId: string;
	isRead: boolean;
	contactName: string;
	currentNode: ISequence;
	nextNodes: ISequence[];
	lastReceived: {
		message: string;
		senderId: string;
		receiverId: string;
		timestamp: string;
		threadId: string;
	};
	lastSent: {
		message: string;
		senderId: string;
		receiverId: string;
		timestamp: string;
		threadId: string;
	} | undefined;
	lastMessage: {
		message: string;
		senderId: string;
		receiverId: string;
		timestamp: string;
		threadId: string;
	};
	primaryCampaignColour: string;
	threadId: string;
	linkedInThreadId: string;
	aiResult: { [s: string]: { confidence: number; value: any } };
	polarity: string;
	inProgress: boolean;
	company: string;
	position: string;
	isConversationRestricted: boolean;
	isLoggedIn: boolean;
	isSnoozed: boolean | undefined;
	messageCount: number;
	tags: ContactTagResponse[];
	filteredTags?: string[];
}
export class InboxMessage extends BaseModel<typeof InboxMessage, IInboxMessage> {
	static modelName = 'InboxMessage';
	static fields = {
		id: attr(),
		campaignId: fk({ to: 'Campaign', as: 'campaign' }),
		campaignName: attr(),
		campaignType: attr(),
		orgMemberId: fk({ to: 'OrganizationMember', as: 'organizationMember' }),
		orgMemberName: attr(),
		campaignMemberId: fk({ to: 'CampaignMember', as: 'campaignMember' }),
		contactId: attr(),
		contactName: attr(),
		currentNode: attr(),
		nextNodes: attr(),
		isRead: attr(),
		lastReceived: attr(),
		lastSent: attr(),
		lastMessage: attr(),
		lastMessageRead: attr(),
		primaryCampaignColour: attr(),
		threadId: attr(),
		linkedInThreadId: attr(),
		aiResult: attr(),
		polarity: attr(),
		status: attr(),
		inProgress: attr(),
		isSnoozed: attr(),
		company: attr(),
		position: attr(),
		isConversationRestricted: attr(),
		isLoggedIn: attr(),
		messageCount: attr(),
		tags: attr(),
	};
}
