import React, { useCallback, useEffect, useState } from 'react';
import HeaderContentLayout from '../layouts/headerContent';
import { useSelector } from 'react-redux';
import { OrganizationMemberSelectors } from '@copilot/common/store/selectors/organizationMember';
import { OrganizationSelectors } from '@copilot/common/store/selectors/organization';
import OrganizationDashboardHeader from './header';
import { useFetch, useRedirect, useSearchParams } from '@copilot/common/hooks/common';
import { OrganizationManager } from '@copilot/data';
import { OrganizationActions } from '@copilot/common/store/actions/organization';
import OrganizationDashboardSummary from './summary';
import OrganizationDashboardSettings from './settings';
import OrganizationDashboardAllTeamMembers from './orgTeamMembers';
import TemplateMessages from '../settings/template';
import BasicContainer from '@copilot/common/components/containers/basic';
import {
	OrganizationDashboardTabKeys,
	OrganizationDashboardTabLabels,
} from '@copilot/common/utils/constant';
import OrganizationNotificationSettings from './notification';
import ConnectionsPage from '../connections';
import { useLocation, useHistory } from 'react-router';
import { Location, History } from 'history';
import { useFetchOrgDashAttentionItems } from '@copilot/common/hooks/attentionItem';
import { OrganizationDashboardAllTags } from './tags';
import { useFeatureToggle } from '@copilot/common/hooks/feature';
import { Features } from '@copilot/data/responses/interface';
import OrganizationSummaryV2 from './summaryV2';
import OrganizationCrmConfiguration from './crmConfiguration';
import { Config } from '@copilot/common/config';
import Blocklist from './blocklist/blocklist';

type OrganizationDashboardProps = {
	organizationId?: string;
	location: { state: boolean };
};

type OrganizationDashboardSearchParams = {
	tab: string;
};

/**
 * Browser History Location State Properties
 * @param {string} teamMembersRedirectMemberId id of the organization member upon redirect to the team members tab (undefined for no redirect / no member selected)
 */
type OrgDashboardLocationState = {
	teamMembersRedirectMemberId: string;
};

export default function OrganizationDashboard(props: OrganizationDashboardProps) {
	const isBlocklistEnabled = useFeatureToggle(Features.DoNotProspectFeature);
	const activeAdmin = useSelector(OrganizationMemberSelectors.getAdminMember);
	const [, renameOrganization] = useFetch(
		OrganizationManager.renameOrganization,
		OrganizationActions.loadOrganization
	);
	const [tab, setTab] = React.useState<string>('');
	const [searchParams, setSearchParams] = useSearchParams<OrganizationDashboardSearchParams>();
	useEffect(() => {
		setTab(searchParams.tab ?? OrganizationDashboardTabKeys.Summary);
	}, [searchParams]);
	const activeMember = useSelector(OrganizationMemberSelectors.getActiveMember);
	if (!activeMember) return null;
	const activeOrganization = useSelector(OrganizationSelectors.getActiveOrganization);
	const organizationId = activeOrganization ? activeOrganization.id : '';
	const [redirect] = React.useState<boolean>(props.location.state);
	useRedirect(redirect, 'Redirected to Dashboard');

	// NOTE: need to use useLocation instead of the redirect value above to get the
	// correct location state when switching between tabs.
	const location: Location<OrgDashboardLocationState | undefined> = useLocation();
	const history: History = useHistory();
	const [teamMembersRedirectMemberId, setTeamMembersRedirectMemberId] = useState<string>();
	useEffect(() => {
		const memberId = location.state?.teamMembersRedirectMemberId;
		if (memberId) {
			setTeamMembersRedirectMemberId(memberId);
			const state = { ...location.state, teamMembersRedirectMemberId: undefined };
			history.replace({ ...location, state });
		}
	}, [location, history]);

	const handleTabChange = React.useCallback(
		(key: string) => {
			setSearchParams({ tab: key });
			setTeamMembersRedirectMemberId(undefined);
		},
		[setSearchParams]
	);

	useFetchOrgDashAttentionItems(organizationId, tab);

	const handleNameUpdate = useCallback(
		(name: string) => activeOrganization?.id && renameOrganization(activeOrganization.id, name),
		[organizationId]
	);

	const showSummaryV2 = useFeatureToggle(Features.OrgHubV2Feature);
	const showCrmClientIntegration = useFeatureToggle(Features.CRMClientIntegrationFeature);

	if (!organizationId) return null;
	return (
		<HeaderContentLayout>
			<HeaderContentLayout.Header>
				<OrganizationDashboardHeader
					organizationName={activeOrganization ? activeOrganization.name : ''}
					onUpdate={handleNameUpdate}
				/>
			</HeaderContentLayout.Header>
			<HeaderContentLayout.Tabs activeKey={tab} onChange={handleTabChange}>
				<HeaderContentLayout.TabPanes
					tab={OrganizationDashboardTabLabels.Summary}
					key={OrganizationDashboardTabKeys.Summary}
				>
					{showSummaryV2 ? (
						<OrganizationSummaryV2 organizationId={organizationId} />
					) : (
						<OrganizationDashboardSummary organizationId={organizationId} />
					)}
				</HeaderContentLayout.TabPanes>
				<HeaderContentLayout.TabPanes
					tab={OrganizationDashboardTabLabels.TeamMembers}
					key={OrganizationDashboardTabKeys.TeamMembers}
				>
					<OrganizationDashboardAllTeamMembers
						organizationId={organizationId}
						initialOrgMemberId={teamMembersRedirectMemberId}
					/>
				</HeaderContentLayout.TabPanes>
				<HeaderContentLayout.TabPanes
					tab={OrganizationDashboardTabLabels.Tags}
					key={OrganizationDashboardTabKeys.Tags}
				>
					<OrganizationDashboardAllTags organizationId={organizationId} />
				</HeaderContentLayout.TabPanes>
				<HeaderContentLayout.TabPanes
					tab={OrganizationDashboardTabLabels.Template}
					key={OrganizationDashboardTabKeys.Template}
				>
					<BasicContainer>
						<BasicContainer.Header>Quick Responses</BasicContainer.Header>
						<BasicContainer.Content>
							<TemplateMessages user={activeMember} showCampaigns hideHeader />
						</BasicContainer.Content>
					</BasicContainer>
				</HeaderContentLayout.TabPanes>
				{isBlocklistEnabled ? (
					<HeaderContentLayout.TabPanes
						tab={OrganizationDashboardTabLabels.Blocklist}
						key={OrganizationDashboardTabKeys.Blocklist}
					>
						<Blocklist />
					</HeaderContentLayout.TabPanes>
				) : null}
				{!Config.isAgency && showCrmClientIntegration ? (
					<HeaderContentLayout.TabPanes
						tab={OrganizationDashboardTabLabels.CRMConfiguration}
						key={OrganizationDashboardTabKeys.CRMConfiguration}
					>
						<OrganizationCrmConfiguration organizationId={organizationId} />
					</HeaderContentLayout.TabPanes>
				) : null}
				<HeaderContentLayout.TabPanes
					tab={OrganizationDashboardTabLabels.Settings}
					key={OrganizationDashboardTabKeys.Settings}
				>
					<OrganizationDashboardSettings organizationId={organizationId} />
				</HeaderContentLayout.TabPanes>
				<HeaderContentLayout.TabPanes
					tab={OrganizationDashboardTabLabels.Notification}
					key={OrganizationDashboardTabKeys.Notification}
				>
					<OrganizationNotificationSettings organizationId={organizationId} />
				</HeaderContentLayout.TabPanes>
				{!!activeAdmin && (
					<HeaderContentLayout.TabPanes
						tab={OrganizationDashboardTabLabels.Connection}
						key={OrganizationDashboardTabKeys.Connection}
					>
						<ConnectionsPage />
					</HeaderContentLayout.TabPanes>
				)}
			</HeaderContentLayout.Tabs>
		</HeaderContentLayout>
	);
}
