import { IMessage } from '@copilot/common/store/models/redux';
import { AIFeatureManager, InboxManager } from '@copilot/data';
import { LinkedInThreadType } from '@copilot/common/store/models/const/enum';
import Store from '@copilot/common/store';
import notificationManager from '@copilot/common/utils/notificationManager';
import { useSelector } from 'react-redux';
import { InboxActions } from '@copilot/common/store/actions/inbox';
import { OrganizationMemberSelectors } from '@copilot/common/store/selectors/organizationMember';
import { Features, TermsOfUse } from '@copilot/data/responses/interface';
import { URL } from '@copilot/common/data/urls';
import { ActivityActions } from '@copilot/common/store/actions/activity';
import { Moment } from 'moment';
import { useSendMessageWithReminderTrackingParamsTracking } from '@copilot/common/components/selector/reminder/tracking';
import { getMillisecondsFromNow } from '@copilot/common/utils/dateUtil';
import { RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useRedirectToTemplate } from '@copilot/common/hooks/common';
import { CampaignDashboardTabKeys } from '@copilot/common/utils/campaign/dashboardTabs';
import MessageForm from '@copilot/common/components/forms/messageForm';
import { useFeatureToggle } from '@copilot/common/hooks/feature';
import { linkedInDisconnectedMsg, manualMessageDisabledMsg } from '../../../../constant/strings';
import { useQuickResponseModal } from '@copilot/common/hooks/quickResponse';
import { useSmartReply } from '@copilot/common/hooks/smartReply';
import Layout from '@copilot/common/components/layout';
import styles from './conversationWrapper.module.less';
import { ConversationProps } from './types';
import ConversationList from './conversationList';
import { getSubmissionFunction } from './conversationSubmitMessageBusinessLogic';
import InboxMessageSelectors from '@copilot/common/pages/inbox/data/selector';
import { getSmartReplyPromptCategory } from '../../../forms/messageForm/smartReply/smartReplyUtils';
import { Config } from '@copilot/common/config';
import { useTermsOfUse } from '@copilot/common/hooks/termsOfUse';

const getLinkedInThreadUrl = (
	conversation: {
		threadId: string;
		threadType: LinkedInThreadType;
	} | null
) => {
	if (conversation?.threadId) {
		const route =
			conversation.threadType === LinkedInThreadType.SalesNav
				? '/sales/inbox/'
				: '/messaging/thread/';
		return `${URL.LINKEDIN_URL}${route}${conversation.threadId}`;
	}
	return undefined;
};

export default function ConversationWrapper({
	messageTemplates,
	contact: contactProp,
	conversation: conversationProp,
	orgMember: orgMemberProp,
	isOwner,
	campaignId,
	onTracking,
	automatedSteps,
	onMessageSent,
}: ConversationProps) {
	const conversationDiv: RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);
	const videoInAppValidationEnabled =
		!Config.isAgency && useFeatureToggle(Features.VideoValidationFeature);
	const [tempOutgoing, setTempOutgoing] = useState<IMessage[]>([]);
	const [isThreadRestricted, setIsThreadRestricted] = useState<boolean>(false);
	const [isLinkedinLoggedOut, setIsLinkedinLoggedOut] = useState<boolean>(false);
	const activeMember = useSelector(OrganizationMemberSelectors.getActiveMember);
	const threadLink = getLinkedInThreadUrl(conversationProp);
	const [smartReplyResult, { writeSmartReply, regenerateSmartReply, editSmartReply }] =
		useSmartReply(orgMemberProp.organizationId, orgMemberProp.id, contactProp.id);

	const isQuickReplyModalFeatureEnabled = useFeatureToggle(
		Features.SmartReplySaveResponseFeature
	);
	const isManualSendMessageFeatureEnabled = useFeatureToggle(
		Features.ManualSendMessageFeature
	);
	const [createQuickReplyModal] = useQuickResponseModal();

	const updateSendWithReminderTracking =
		useSendMessageWithReminderTrackingParamsTracking('Send With Reminder');
	const redirectToTemplate = useRedirectToTemplate(CampaignDashboardTabKeys.Template);

	const messages = useSelector(InboxMessageSelectors);

	const messageItem = useMemo(() => {
		return messages?.data?.find((m) => m.linkedInThreadId === conversationProp.threadId);
	}, [messages, conversationProp.threadId]);

	const { hasAccepted: hasAcceptedAITermsOfUse } = useTermsOfUse(
		TermsOfUse.AIFeature,
		AIFeatureManager.acceptTermsOfUse
	);

	useEffect(() => {
		setIsThreadRestricted(conversationProp.isThreadRestricted);
		setIsLinkedinLoggedOut(!conversationProp.isLoggedIn);
	}, [conversationProp.isConversationRestricted, conversationProp.isLoggedIn]);

	const onSubmitMessage = getSubmissionFunction(
		orgMemberProp,
		conversationProp,
		campaignId,
		contactProp,
		tempOutgoing,
		onTracking,
		setTempOutgoing,
		setIsThreadRestricted,
		messageItem,
		onMessageSent
	);

	const onTriggerReminder = useCallback(
		async (date: Moment) => {
			const { id } = conversationProp;
			const reminderDate = date.toISOString();
			try {
				await InboxManager.snoozeThread(id, reminderDate);
				const interval = getMillisecondsFromNow(date);
				updateSendWithReminderTracking('Sent from Prospect Drawer', interval);
				notificationManager.showSuccessNotification({
					message: `Reminder set for ${date.format('ddd MMM Do')}`,
					description: '',
				});
				Store.Dispatch(
					ActivityActions.updateConversation({
						id,
						isSnoozed: true,
						snoozedDateTime: reminderDate,
					})
				);
				Store.Dispatch(
					InboxActions.updateInboxMessage({
						id,
						isSnoozed: true,
						isRead: true,
					})
				);
			} catch {
				notificationManager.showErrorNotification({
					message: 'Failed to set Reminder',
					description: 'Please try again.',
				});
			}
		},
		[conversationProp.id]
	);
	
	function getPlaceholderMessage() {
		if (!isManualSendMessageFeatureEnabled) {
			return manualMessageDisabledMsg;
		} else if (isLinkedinLoggedOut) {
			return linkedInDisconnectedMsg;
		} else {
			return undefined;
		}
	}

	return (
		<Layout contentClassName={styles.conversationWrapper}>
			<Layout.Content>
				<div className={styles.contentWrapper} ref={conversationDiv}>
					{
						<ConversationList
							contact={contactProp}
							conversation={conversationProp}
							orgMember={orgMemberProp}
							threadLink={threadLink}
							onTracking={onTracking}
							tempOutgoing={tempOutgoing}
							activeMember={activeMember}
						/>
					}
				</div>
			</Layout.Content>
			<Layout.Footer>
				{isOwner ? (
					<div className={styles.contentFooter}>
						<MessageForm
							messageTemplates={messageTemplates}
							isThreadRestricted={isThreadRestricted}
							isDisabled={isThreadRestricted || !activeMember || isLinkedinLoggedOut || !isManualSendMessageFeatureEnabled}
							isUsingTemplateDropdown
							onSetupTemplate={
								isQuickReplyModalFeatureEnabled
									? (formMessage: string) => {
											createQuickReplyModal(
												[contactProp.campaignIds[0]],
												formMessage
											);
									  }
									: () => redirectToTemplate(contactProp.campaignIds[0] ?? '')
							}
							onSubmit={onSubmitMessage}
							onSetReminder={onTriggerReminder}
							placeholder={getPlaceholderMessage()}
							chatGPTWriteRetryCallback={regenerateSmartReply}
							chatGPTWriteCallback={writeSmartReply}
							chatGPTEditCallback={editSmartReply}
							chatGPTQueryResult={smartReplyResult}
							automatedSteps={automatedSteps}
							smartReplyPromptCategory={getSmartReplyPromptCategory(messageItem)}
							videoInAppValidationEnabled={videoInAppValidationEnabled}
							hasAcceptedAITermsOfUse={hasAcceptedAITermsOfUse}
						/>
					</div>
				) : null}
			</Layout.Footer>
		</Layout>
	);
}
