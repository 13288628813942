import React from 'react';
import TableModule from '@copilot/common/components/tables/tableModule';
import drawerManager from '@copilot/common/utils/drawerManager';
import { ERROR_ICON, ISSUE_ICON, OK_ICON } from '@copilot/common/components/icon';
import { IOrganizationTeamMember } from '@copilot/common/pages/organizationDashboard/orgTeamMembers/data/models';
import { Button, Col, Row } from 'antd';
import { SettingOutlined } from '@ant-design/icons';
import { isReactElement } from '@copilot/common/utils';
import { TableModuleTableProps } from '../../tableModule/types';
import {
	TableModuleColumn,
	TableModuleHeader,
	TableModuleStandardTitle,
} from '../../tableModule/util';
import { TableModuleColumnSelector } from '../../tableModule/columnSelector';
import { TableModuleTable } from '../../tableModule/table';

interface IOrganizationMemberTableHeader {}

/**
 * Organization Member Table Header
 */
const OrganizationMemberTableHeader: React.FC<IOrganizationMemberTableHeader> = ({ children }) => (
	<>{children}</>
);

const getHeader = (children: React.ReactNode) => {
	let header: React.ReactNode = null;
	React.Children.map(children, (child) => {
		if (isReactElement(child)) {
			switch (child.type) {
				case OrganizationMemberTableHeader:
					header = child;
					break;
			}
		}
	});
	return header;
};

const generateOnCell = (record: IOrganizationTeamMember) => ({
	onClick: () => drawerManager.openAdminMemberDrawer({ id: record.id }),
});

const renderLinkedInSync = (_: any, record: IOrganizationTeamMember) => {
	if (record.isLinkedInLoggedIn && !record.hasInvalidSalesNavSubscription) {
		return OK_ICON;
	} else if (record.isLinkedInLoggedIn && record.hasInvalidSalesNavSubscription) {
		return ISSUE_ICON;
	} else {
		return ERROR_ICON;
	}
};

const renderStatus = (isActive: IOrganizationTeamMember['isActive']) =>
	isActive ? 'Active' : 'Deactivated';

const renderSettingsButton = (_: unknown, record: IOrganizationTeamMember) => (
	<Row justify="space-around">
		<Col>
			<Button icon={<SettingOutlined />} onClick={generateOnCell(record).onClick} />
		</Col>
	</Row>
);

interface OrganizationMemberTableProps extends TableModuleTableProps<IOrganizationTeamMember> {}

/**
 * [Presentational] Table to show all organization team members
 * @param {OrganizationMemberTableProps} props
 */
function OrganizationAllTeamMembersTable(props: OrganizationMemberTableProps) {
	const { children, ...modelTableModuleProps } = props;
	const header = getHeader(children);

	return (
		<TableModule
			header={
				<TableModuleHeader>
					<Row justify="space-around" align={'middle'}>
						<Col style={{ flexGrow: 1 }}>
							{' '}
							<TableModuleStandardTitle>{header}</TableModuleStandardTitle>{' '}
						</Col>
						<Col>
							<TableModuleColumnSelector />
						</Col>
					</Row>
				</TableModuleHeader>
			}
			table={
				<TableModuleTable
					rowKey="id"
					rowClassName="show-button-on-hover"
					{...modelTableModuleProps}
				>
					<TableModuleColumn
						sorter={{ compare: (a, b) => b.name.localeCompare(a.name) }}
						defaultSortOrder={'descend'}
						title="Name"
						columnKey="name"
						dataIndex="name"
						onCell={generateOnCell}
						className="column-name clickable"
						width={'25%'}
					/>
					<TableModuleColumn
						title="Email"
						sorter={{ compare: (a, b) => a.email.localeCompare(b.email) }}
						columnKey="email"
						dataIndex="email"
						onCell={generateOnCell}
						className="column-email clickable"
						width={'25%'}
					/>
					<TableModuleColumn
						title="LinkedIn Synced"
						sorter={{
							compare: (a, b) =>
								a.isLinkedInLoggedIn > b.isLinkedInLoggedIn ? 1 : -1,
						}}
						columnKey="linkedInSync"
						dataIndex="isLinkedInLoggedIn"
						render={renderLinkedInSync}
						onCell={generateOnCell}
						className="clickable"
						width={'5%'}
					/>
					<TableModuleColumn
						title="Status"
						sorter={{
							compare: (a, b) => (a.isActive > b.isActive ? 1 : -1),
						}}
						columnKey="status"
						dataIndex="isActive"
						render={renderStatus}
						onCell={generateOnCell}
						className="column-status clickable"
						width={'5%'}
					/>
					<TableModuleColumn
						title=""
						columnKey="manage"
						hideable={false}
						width={'5%'}
						render={renderSettingsButton}
						className="column-status"
					/>
				</TableModuleTable>
			}
		/>
	);
}

OrganizationAllTeamMembersTable.Header = OrganizationMemberTableHeader;

export default OrganizationAllTeamMembersTable;
