import React from 'react';
import {
	CheckSquareOutlined,
	WarningOutlined,
	ExclamationCircleTwoTone,
	CloseSquareOutlined,
} from '@ant-design/icons';
import { Button, Popover } from 'antd';

export const OK_ICON = <CheckSquareOutlined style={{ fontSize: '1.5em', color: 'green' }} />;

export const WARNING_ICON = <WarningOutlined style={{ fontSize: '1.5em', color: 'red' }} />;

export const ISSUE_ICON = <WarningOutlined style={{ fontSize: '1.5em', color: 'orange' }} />;

export const ERROR_ICON = <CloseSquareOutlined style={{ fontSize: '1.5em', color: 'red' }} />;

export const DEACTIVATE_BUTTON = (
	<Button danger type="primary">
		Deactivate
	</Button>
);

export const REACTIVATE_BUTTON = (props: React.ComponentProps<typeof Button>) => (
	<Button {...props}>Re-activate</Button>
);

export const WarningInfoIcon: React.FC<{ errorMsg: string; color?: string }> = (props) => {
	const { errorMsg, color } = props;
	return (
		<Popover placement="top" content={errorMsg} trigger="hover">
			<ExclamationCircleTwoTone twoToneColor={color} />
		</Popover>
	);
};
