import { SendOutlined } from '@ant-design/icons';
import { SmartReplyButton } from '@copilot/common/components/forms/messageForm/smartReply/smartReplyButton';
import { SmartTextEditorProps } from './types';
import { useSelector } from 'react-redux';
import { OrganizationSelectors } from '@copilot/common/store/selectors/organization';
import { useSmartReply } from '@copilot/common/hooks/smartReply';
import { useTermsOfUse } from '@copilot/common/hooks/termsOfUse';
import { Features, TermsOfUse } from '@copilot/data/responses/interface';
import { AIFeatureManager } from '@copilot/data';
import { InboxMessageType } from '@copilot/common/pages/inbox/data/types';
import { ReactNode, useEffect, useState } from 'react';
import modalManager from '@copilot/common/utils/modalManager';
import { getSmartReplyPromptCategory } from '@copilot/common/components/forms/messageForm/smartReply/smartReplyUtils';
import isNil from 'lodash/isNil';
import { useSmartReplyPrompts } from '@copilot/common/hooks/smartReply/prompts';
import isEmpty from 'lodash/isEmpty';
import { Alert, Button, Flex, Typography } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import {
    CUSTOM_PROMPT_KEY,
    WriteSmartReplyErrors,
    WriteSmartReplyQueryType,
} from '@copilot/common/hooks/smartReply/smartReplyTypes';
import {
    OperationStatus,
    OperationStatuses,
    StatusIndicator,
} from '@copilot/common/components/statusIndicator/statusIndicator';
import { Config } from '@copilot/common/config';
import styles from './card.module.less';
import delay from 'lodash/delay';
import {
	linkedInDisconnectedMsg,
	linkedInRestrictedMsg,
	manualMessageDisabledMsg,
} from '@copilot/common/constant/strings';
import { useFeatureToggle } from '@copilot/common/hooks/feature';

const { Text, Link } = Typography;

/**
 * Hook to get all functionality for smart reply
 */
function useSmartReplyManagement(replyText: string, receivedMessage: InboxMessageType) {
    const organizationId = useSelector(OrganizationSelectors.getActiveOrganizationId);
    const [smartReplyResult, { writeSmartReply, regenerateSmartReply, editSmartReply }] =
        useSmartReply(organizationId, receivedMessage.orgMemberId, receivedMessage.contactId);
    const { hasAccepted: hasAcceptedAITermsOfUse } = useTermsOfUse(
        TermsOfUse.AIFeature,
        AIFeatureManager.acceptTermsOfUse
    );
    const [isAssistantActive, setIsAssistantActive] = useState<boolean>(false);
    const [hasGenerated, setHasGenerated] = useState<boolean>(false);
    const [isStatusVisible, setStatusVisibility] = useState<boolean>(false);
    const { operationStatus, operationMessage } = getOperationStatus(smartReplyResult);
    const { getByKey: getPromptByKey } = useSmartReplyPrompts();

    useEffect(() => {
        if (isEmpty(smartReplyResult.data)) return;
        setHasGenerated(true);
        const delayId = delay(() => setStatusVisibility(false), 5000);
        return () => clearTimeout(delayId);
    }, [smartReplyResult.data]);

    useEffect(() => {
        if (smartReplyResult.loading) setStatusVisibility(true);
    }, [smartReplyResult.loading]);

    useEffect(() => {
        if (smartReplyResult.error?.message === WriteSmartReplyErrors.outOfCredit) {
            setStatusVisibility(false);
            onOutOfCredit();
        }
    }, [smartReplyResult.error?.message]);

    function onNotAvailable() {
        modalManager.openAIFeatureTermsOfUseModal({
            onSubmit: () => setIsAssistantActive(true),
        });
    }

    function onOutOfCredit() {
        modalManager.openOutOfCreditModal({});
    }

    /**
     * Function called when user selects a preset prompt
     * @param promptKey
     */
    function onSelectPreset(promptKey: string) {
        setIsAssistantActive(false);
        const prompt = getPromptByKey(promptKey);
        if (isNil(prompt)) throw new Error(`Prompt with key ${promptKey} not found`);
        prompt.action == 'EDIT'
            ? editSmartReply(replyText, prompt.value).catch((err) => console.error(err))
            : writeSmartReply(prompt.value).catch((err) => console.error(err));
    }

    /**
     * Function called when user creates a custom prompt
     * @param prompt
     */
    function onSubmitCustomPrompt(prompt: string, isEditing: boolean) {
        setIsAssistantActive(false);
        // TODO: When removing ChatCompletion functionality, should use `onGenerate('custom', prompt)`
        isEditing
            ? editSmartReply(replyText, CUSTOM_PROMPT_KEY, prompt).catch((err) => console.error(err))
            : writeSmartReply(CUSTOM_PROMPT_KEY, prompt).catch((err) => console.error(err));
    }

    return {
        smartReplyResult,
        writeSmartReply,
        regenerateSmartReply,
        editSmartReply,
        hasAcceptedAITermsOfUse,
        isAssistantActive,
        setIsAssistantActive,
        hasGenerated,
        onNotAvailable,
        onOutOfCredit,
        isStatusVisible,
        operationStatus,
        operationMessage,
        onSelectPreset,
        onSubmitCustomPrompt,
        smartReplyPromptCategory: getSmartReplyPromptCategory(receivedMessage),
    };
}

function getOperationStatus(data: WriteSmartReplyQueryType): {
    operationStatus: OperationStatus;
    operationMessage: ReactNode;
} {
    if (data.error) {
        if (data.error.message === WriteSmartReplyErrors.moderation) {
            return {
                operationStatus: OperationStatuses.error,
                operationMessage: (
                    <Text>
                        The prompt or conversation history violates{' '}
                        <Link href={Config.termsAndServicesURL} target="_blank">
                            terms of usage
                        </Link>
                        .
                    </Text>
                ),
            };
        }

        return {
            operationStatus: OperationStatuses.error,
            operationMessage: <Text>We're not currently able to connect to this service.</Text>,
        };
    } else if (data.loading) {
        // If data is loading, update status to in progress
        return {
            operationStatus: OperationStatuses.inProgress,
            operationMessage: <Text>AI is writing</Text>,
        };
    } else {
        // If it did not error out nor loading, set operation status to complete
        return {
            operationStatus: OperationStatuses.complete,
            operationMessage: <Text>Done!</Text>,
        };
    }
}

/**
 * Text editor integrated to use SmartReply
 * @returns
 */
export default function SmartTextEditor({
    onSubmit: handleSubmit,
    isLoading,
    receivedMessage,
}: SmartTextEditorProps) {
    const [msgText, setMsgText] = useState<string>('');
    const { isLoggedIn, isConversationRestricted } = receivedMessage;
    const isManualSendMessageFeatureEnabled = useFeatureToggle(
      Features.ManualSendMessageFeature
    );
    const hasValidMessage = !isEmpty(msgText.trim());
    const {
        smartReplyResult,
        isAssistantActive,
        setIsAssistantActive,
        hasGenerated,
        regenerateSmartReply,
        hasAcceptedAITermsOfUse,
        onNotAvailable,
        smartReplyPromptCategory,
        isStatusVisible,
        operationStatus,
        operationMessage,
        onSelectPreset,
        onSubmitCustomPrompt,
    } = useSmartReplyManagement(msgText, receivedMessage);

    function onSubmit() {
        handleSubmit(msgText);
        setMsgText('');
    }

    function calcPlaceholder() {
        if (!isManualSendMessageFeatureEnabled) {
            return manualMessageDisabledMsg;
        } else if (!isLoggedIn) {
            return linkedInDisconnectedMsg;
        } else if (isConversationRestricted) {
            return linkedInRestrictedMsg;
        }
        return 'Write a reply';
    }
    
    const isDisabled = !isLoggedIn || isLoading || isConversationRestricted || !isManualSendMessageFeatureEnabled;

    const isSmartReplyGenerating = operationStatus === OperationStatuses.inProgress;

    useEffect(() => {
        if (isNil(smartReplyResult.data)) return;
        setMsgText(smartReplyResult.data);
    }, [smartReplyResult.data]);

    return (
        <Flex vertical gap="small">
            {isStatusVisible === true && !isSmartReplyGenerating && (
                <StatusIndicator status={operationStatus}>{operationMessage}</StatusIndicator>
            )}
            {!isStatusVisible && hasGenerated ? (
                <Alert
                    className={styles.reviewResponseAlert}
                    type="info"
                    message="Review the AI response for accuracy."
                    showIcon
                />
            ) : null}
            <Flex gap={'small'} align="flex-start">
                {isStatusVisible === true && isSmartReplyGenerating && (
                    <StatusIndicator
                        className={styles.aiIsWritingIndicator}
                        status={operationStatus}
                    >
                        {operationMessage}
                    </StatusIndicator>
                )}
                {!smartReplyResult.loading && (
                    <SmartReplyButton
                        hasMessage={hasValidMessage}
                        isAvailable={hasAcceptedAITermsOfUse}
                        setIsActive={setIsAssistantActive}
                        isActive={isAssistantActive}
                        onNotAvailable={onNotAvailable}
                        canRegenerate={hasGenerated}
                        onRegenerate={regenerateSmartReply}
                        onSubmitCustomPrompt={onSubmitCustomPrompt}
                        onSelectPreset={onSelectPreset}
                        disabled={isDisabled}
                        smartReplyPromptCategory={smartReplyPromptCategory}
                    />
                )}
                <TextArea
                    autoSize={{ minRows: 1, maxRows: 8 }}
                    placeholder={calcPlaceholder()}
                    onChange={(e) => setMsgText(e.target.value)}
                    disabled={isDisabled}
                    value={msgText}
                />
                <Button
                    icon={<SendOutlined />}
                    type={'primary'}
                    onClick={onSubmit}
                    disabled={!hasValidMessage || isDisabled}
                    loading={isLoading}
                >
                    Send
                </Button>
            </Flex>
        </Flex>
    );
}
