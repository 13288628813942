import { LinkedInThreadType } from '../const/enum';
import { BaseModel } from './base';
import { attr, SessionBoundModel } from 'redux-orm';
import { LinkedInMessageStatus } from '@copilot/data/responses/interface';
import { UtilityFunctions } from '@copilot/common/utils/common';
import { some } from 'lodash';
import { InMailStatusConstants } from '@copilot/common/constant/inMailConstant';

export interface IMessage {
	subject?: string;
	data: string;
	isSelf: boolean;
	timestamp?: Date;
	pending?: boolean;
	status: LinkedInMessageStatus;
	inMailStatus?: string;
	triggerDate?: Date;
}

export class Message extends BaseModel<typeof Message, IMessage> {
	static modelName = 'Message';
	static fields = {
		data: attr(),
		isSelf: attr(),
		timestamp: attr(),
		triggerDate: attr(),
		status: attr(),
		pending: attr(),
	};
}

export interface IMessage {
	data: string;
	isSelf: boolean;
	timestamp?: Date;
	pending?: boolean;
	status: LinkedInMessageStatus;
	triggerDate?: Date;
}

interface InstanceFields {
	messages: Message[];
}

interface Fields {
	id: string;
	sourceId: string;
	sourceName: string;
	targetId: string;
	targetName: string;
	threadId: string;
	threadType: LinkedInThreadType;
	receivedMessages: IMessage[];
	sentMessages: IMessage[];
	isConversationRestricted: boolean;
	isLoggedIn: boolean;
	isSnoozed: boolean;
	snoozedDateTime?: string;
}

export type IConversationThread = InstanceFields & Fields;

export class ConversationThread extends BaseModel<typeof ConversationThread, IConversationThread> {
	static modelName = 'ConversationThread';
	static fields = {
		id: attr(),
		sourceId: attr(),
		sourceName: attr(),
		targetId: attr(),
		targetName: attr(),
		threadId: attr(),
		threadType: attr(),
		sentMessages: attr(),
		receivedMessages: attr(),
		isConversationRestricted: attr(),
		isLoggedIn: attr(),
		isSnoozed: attr(),
	};

	// @ts-expect-error disabling for 'get' and 'set' accessors cannot declare 'this' parameters
	public get messages(this: SessionBoundModel<ConversationThread>) {
		return this.mergeMessages(this.sentMessages, this.receivedMessages);
	}

	/**
	 * Whether or not the current thread is restricted, based on the message that the thread uses
	 */
	// @ts-expect-error disabling for 'get' and 'set' accessors cannot declare 'this' parameters
	public get isThreadRestricted(this: SessionBoundModel<ConversationThread>): boolean {
		if (this.hasInMailAccepted()) {
			return false;
		}

		return this.isConversationRestricted;
	}

	/**
	 * Whether or not the prospect has accepted an inmail request
	 * @private
	 */
	private hasInMailAccepted(this: SessionBoundModel<ConversationThread>) {
		return some(
			this.receivedMessages,
			(message) => message.inMailStatus === InMailStatusConstants.Accepted
		);
	}

	compareDates = (a?: Date, b?: Date) => {
		if (a === undefined) return -1;
		if (b === undefined) return 1;
		const isGreater = a <= b;
		switch (isGreater) {
			case true:
				return -1;
			case false:
				return 1;
			default:
				return UtilityFunctions.assertUnreachable(isGreater);
		}
	};

	mergeMessages = (sentMessages: IMessage[] = [], receivedMessages: IMessage[] = []) => {
		const sent = sentMessages.map((m) => {
			m.isSelf = true;
			return m;
		});
		const received = receivedMessages.map((m) => {
			m.isSelf = false;
			return m;
		});
		return [...sent, ...received].sort((s, r) => this.compareDates(s.timestamp, r.timestamp));
	};
}
