import { attr, fk, SessionBoundModel } from 'redux-orm';
import { BaseModel } from './base';
import { Organization } from './organization';
import { LinkedInMeta } from './linkedinMeta';
import { OrgRoleTitles, RoleTitles } from '../const/enum';

interface Fields {
	id: string;
	userId: string;
	organizationId: string;
	firstName: string;
	lastName: string;
	email: string;
	phoneNumber: string;
	orgRoles: string[];
	userRoles: string[];
	vpnPort: number;
	salesNavContractName: string;
	withdrawExternalInvites: boolean;
	weeksBeforeWithdrawal: number;
	orgType: number;
	userCreatedDate: number;
	alternateEmail: string;
	cc: string[];
	bcc: string[];
	startDate: string;
	stopDate: string;
	customerStatus: string;
	customerSuccessId: string;
	unpauseDate?: string;
	isNotificationEnable: boolean;
	isActive: boolean;
	isOnboarded: boolean;
	hasInvalidSalesNavSubscription: boolean;
}

interface InstanceFields {
	linkedInMeta: LinkedInMeta;
	organization: Organization;
	isLinkedInLoggedIn: boolean;
	isOrgAdmin: boolean;
	name: string;
}

export type IOrganizationMember = Fields & InstanceFields;

export class OrganizationMember extends BaseModel<typeof OrganizationMember, IOrganizationMember> {
	static modelName = 'OrganizationMember';
	static fields = {
		id: attr(),
		userId: attr(),
		organizationId: fk({ to: 'Organization', as: 'organization' }),
		firstName: attr(),
		lastName: attr(),
		email: attr(),
		phoneNumber: attr(),
		orgRoles: attr(),
		userRoles: attr(),
		vpnPort: attr(),
		salesNavContractName: attr(),
		withdrawExternalInvites: attr(),
		orgType: attr(),
		userCreatedDate: attr(),
		alternateEmail: attr(),
		cc: attr(),
		bcc: attr(),
		startDate: attr(),
		stopDate: attr(),
		customerStatus: attr(),
		customerSuccessId: attr(),
		isNotificationEnable: attr(),
		unpauseDate: attr(),
		isOnboarded: attr(),
		hasInvalidSalesNavSubscription: attr(),
	};

	// @ts-expect-error disabling for 'get' and 'set' accessors cannot declare 'this' parameters
	public get isLinkedInLoggedIn(this: SessionBoundModel<OrganizationMember>) {
		return !!this.linkedInMeta?.isLoggedIn;
	}

	/**
	 * Return whether the user is an organization admin
	 */
	// @ts-expect-error disabling for 'get' and 'set' accessors cannot declare 'this' parameters
	public get isOrgAdmin(this: SessionBoundModel<OrganizationMember>): boolean {
		return (
			this.orgRoles.includes(OrgRoleTitles.Admin) ||
			this.orgRoles.includes(OrgRoleTitles.Owner)
		);
	}

	/**
	 * Return whether the user is a system admin
	 */
	// @ts-expect-error disabling for 'get' and 'set' accessors cannot declare 'this' parameters
	public get isSysAdmin(this: SessionBoundModel<OrganizationMember>) {
		return (
			this.userRoles.includes(RoleTitles.Admin) ||
			this.userRoles.includes(RoleTitles.CustomerSuccess) ||
			this.userRoles.includes(RoleTitles.System)
		);
	}

	/**
	 * Get the full name of the organization member
	 */
	// @ts-expect-error disabling for 'get' and 'set' accessors cannot declare 'this' parameters
	public get name(this: SessionBoundModel<OrganizationMember>) {
		const first: string = this.firstName || '';
		const last: string = this.lastName || '';
		return `${first}${last ? ' ' : ''}${last}`;
	}
}
