export enum ThreadStatus {
	Canceled = -3,
	Failed = -2,
	Unknown = -1,
	Pending,
	Success,
}

export enum TagLevel {
	Unknown = -1,
	Organization,
	Campaign,
	System,
}

export enum LinkedInSentMessageError {
	Unknown = -1,
	NoError,
	Network,
	ThreadRestricted,
	StateChanged,
	RecipientMismatch,
	LoadFailed,
	Disconnected,
	AccountClosed,
	LoggedOut,
	RequireApproval,
}

export enum SearchCriteriaType {
	Template = 'Template',
	User = 'User',
}

export enum SchedulingStatus {
	Failed = -1,
	OnSchedule,
	Sent,
}

export enum SentimentMap {
	'Interested' = 'Interested',
	'NotInterested' = 'NotInterested',
	'Maybe' = 'Maybe',
	'InProgress' = 'InProgress',
}

export enum OrganizationType {
	'Enterprise' = 0,
	'Individual' = 1,
}

//Node condition model definition

export enum NodeConditionTypeEnum {
	Response = 'Response',
	Timer = 'Timer',
}

export enum NodeConditionTimeTypeEnum {
	None = 'None',
	After = 'After',
	Before = 'Before',
}

export enum NodeConditionResponseTypeEnum {
	None = 'None',
	NoResponse = 'NoResponse',
	EvaluateResponse = 'EvaluateResponse',
	AnyResponse = 'AnyResponse',
}

export enum NodeTimerPeriodStringEnum {
	Minutes = 'Minutes',
	Hours = 'Hours',
	Days = 'Days',
	Weeks = 'Weeks',
	Months = 'Months',
}

export enum NodeTimerPeriodEnum {
	Minutes = 0,
	Hours,
	Days,
	Weeks,
	Months,
}

export enum AIThresholdEnum {
	Low = 'Low',
	Med = 'Med',
	High = 'High',
}

export enum AIThresholdValueEnum {
	Low = '75',
	Med = '85',
	High = '95',
}

export enum NodeActionType {
	SendMessage = 'SendMessage',
	WithdrawInvite = 'WithdrawInvite',
	AddTag = 'AddTag',
	LinkedInInvite = 'LinkedInInvite',
	LinkedInConnected = 'LinkedInConnected',
}

export enum RoleTitles {
	Admin = 'Admin',
	Member = 'Member',
	System = 'System',
	CustomerSuccess = 'CustomerSuccess',
}

export enum OrgRoleTitles {
	Owner = 'OrgOwner',
	Admin = 'OrgAdmin',
	User = 'OrgUser',
	Advanced = 'OrgAdvanced',
}

export enum LegacyAdminMemberSelectionTypes {
	AdminUser = 'Admin User',
	BaseUser = 'Base User',
}

export enum AdminMemberSelectionTypes {
	AdminUser = 'Administrator',
	BaseUser = 'Base',
	AdvancedUser = 'Advanced',
}

export enum CampaignRoleTitles {
	Admin = 'CampaignAdmin',
	Manager = 'CampaignManager',
	Member = 'CampaignMember',
}

// const MemberRoles = Object.values(RoleTitles).filter(r => r !== RoleTitles.System);
const OrganizationRoles = Object.values(OrgRoleTitles);
// const CampaignRoles = Object.values(CampaignRoleTitles);

export namespace RoleGroups {
	export const All = [undefined, ...OrganizationRoles];
	export const Guest = [undefined];
	export const Member = [...OrganizationRoles];
	export const Admin = [OrgRoleTitles.Admin];
	export const SysAdmin = [RoleTitles.Admin, RoleTitles.CustomerSuccess, RoleTitles.System];
}

export enum MessageStatus {
	Completed = 'Completed',
	Pending = 'Pending',
	Scheduled = 'Scheduled',
	Failed = 'Failed',
}

export enum LinkedInThreadType {
	Unknown = 0,
	Basic = 1,
	SalesNav = 2,
}

export enum NotificationType {
	Pending = 'Pending',
	Error = 'Error',
	Scheduled = 'Scheduled',
	Sent = 'Sent',
	Inbox = 'Inbox',
	LoggedOut = 'LOGGED_OUT',
	Actions = 'Actions',
	MemberActions = 'MemberActions',
}

export enum NotificationActionType {
	None = 0,
	Button = 1,
}

export enum ErrorStatus {
	LoggedOut,
	Blacklisted,
	LowInvites,
	LowSearchList,
	HasFailedMessages,
	LowConnRate,
	InvitesThrottled,
	MissingSalesNavigator,
}

export const ErrorsMap: { [x: string]: number } = {
	LoggedOut: 1 << 0,
	Blacklisted: 1 << 1,
	LowInvites: 1 << 2,
	LowSearchList: 1 << 3,
	HasFailedMessages: 1 << 4,
	LowConnRate: 1 << 5,
	InvitesThrottled: 1 << 6,
	MissingSalesNavigator: 1 << 7,
};

export enum CustomerStatus {
	Onboard,
	Customer,
	OnHold,
	Churned,
	Saving,
}

export const Evaluators = {
	CaseInsensitiveContains: '@*=',
	Equal: '==',
	LargerOrEqual: '>=',
	Larger: '>',
};

export enum NurtureListOptionType {
	Empty = 0,
	Old = 1,
	Tag = 2,
	NeverReplied = 3,
}

export enum NurtureMessageTypeEnum {
	None = 0,
	Info = 1,
	Event = 2,
	CheckIn = 3,
	Business = 4,
}
